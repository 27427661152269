import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import Layout from '@components/layout'
import Header from '@components/header'
import SEO from '@components/seo'

const NotFoundPage = ({ data }) => {
  const image = { localFile: data.backgroundImage }
  return (
    <Layout lang="en-gb">
      <SEO title="404: Not found" />
      <Header image={image} title="404: Not found" text="That fruit went bad. Try picking another?" />
    </Layout>
  )
}

NotFoundPage.propTypes = {
  data: PropTypes.object
}

export default NotFoundPage

export const query = graphql`query {
  backgroundImage: file (name: { eq: "FruitPicking"}) {
    childImageSharp {
      fluid (
        maxWidth: 1000,
        quality: 85,
        duotone: { highlight: "#000000", shadow: "#000000", opacity: 20 }
      ) {
        ...GatsbyImageSharpFluid
      }
    }
  }
}`
